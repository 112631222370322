<template>
  <base-layout-two page-title="Edit Room" :page-default-back-link="`/rooms/${room.id}`">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Room Details">
          <ion-grid>
            <ion-row>
              <!-- property -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Property *">
                  <v-field
                    name="property"
                    v-slot="{ field }"
                    v-model="room.property_id"
                    :rules="required"
                  >
                    <select
                      v-bind="field"
                      @change="newPropertyUpdateRoomTypes()"
                      class="regular-select"
                    >
                      <option
                        v-for="property in properties"
                        :key="property.id"
                        :value="property.id"
                      >
                        {{ property.name }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="property" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Room Type -->
              <!-- using v-if length > 0 as a temporary bug fix for ion-select not showing up from http requests -->
              <ion-col size="12" size-lg="6" v-if="room_types.length > 0">
                <base-input label-text="Room Type *">
                  <v-field
                    name="room_type"
                    v-slot="{ field }"
                    v-model="room.room_type_id"
                    :rules="required"
                  >
                    <select v-bind="field" class="regular-select">
                      <option
                        v-for="room_type in room_types"
                        :key="room_type.id"
                        :value="room_type.id"
                      >
                        {{ room_type.name }}
                      </option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="room_type" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Room Number -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Room Number *">
                  <v-field
                    name="number"
                    v-slot="{ field }"
                    v-model="room.number"
                    :rules="required"
                  >
                    <ion-input
                      name="number"
                      v-bind="field"
                      placeholder="Room Number"
                      type="text"
                      autocapitalize="words"
                      autocorrect
                    ></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="number" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Flex -->
              <ion-col
                size="12"
                size-lg="6"
              >
                <base-input label-text="Flex Room?">
                  <v-field name="flex" v-slot="{ field }" v-model="room.flex">
                    <select v-bind="field" class="regular-select">
                      <option :value="0">No</option>
                      <option :value="1">Yes</option>
                    </select>
                  </v-field>
                </base-input>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,

    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      room: {},
      properties: [],
      room_types: [],
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
    await this.fetchRoom();
    await this.fetchProperties();
    await this.fetchRoomTypes();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchRoom() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/rooms/${this.$route.params.id}`)
        .then((response) => {
          this.room = response.data.room;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchRoomTypes() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/room_types?property_id=${this.room.property_id}`
        )
        .then((response) => {
          this.room_types = response.data.room_types;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async newPropertyUpdateRoomTypes() {
      // Load new Room Types
      await this.fetchRoomTypes();

      // Set Room Type ID to null to force user to select a new Room Type
      this.room.room_type_id = null;
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/rooms/${this.$route.params.id}`,
        data: this.room,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.room = response.data.room;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Room Updated", color: "secondary" });

          this.$router.replace(`/rooms/${this.room.id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>